import { render, staticRenderFns } from "./Typography.vue?vue&type=template&id=68f694d6&"
import script from "./Typography.vue?vue&type=script&lang=js&"
export * from "./Typography.vue?vue&type=script&lang=js&"
import style0 from "./Typography.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("D:\\_Dr\\_git\\poliglotiki-front\\crm-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('68f694d6')) {
      api.createRecord('68f694d6', component.options)
    } else {
      api.reload('68f694d6', component.options)
    }
    module.hot.accept("./Typography.vue?vue&type=template&id=68f694d6&", function () {
      api.rerender('68f694d6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/component/Typography.vue"
export default component.exports