var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "typography", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Typography", link: "styles/typography" }
      }),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "base-material-card",
                {
                  attrs: { color: "green" },
                  scopedSlots: _vm._u([
                    {
                      key: "heading",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "display-2 font-weight-light" },
                            [
                              _vm._v(
                                "\n            Material Dashboard Heading\n          "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "subtitle-1 font-weight-light" },
                            [
                              _vm._v(
                                "\n            Created using Roboto Font Family\n          "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { staticClass: "pa-0", attrs: { fluid: "" } },
                        _vm._l(_vm.typography, function(t, i) {
                          return _c(
                            "v-row",
                            { key: i, attrs: { align: "center" } },
                            [
                              _c("v-col", { attrs: { cols: "1", md: "3" } }, [
                                _c("span", {
                                  staticClass: "tim-note",
                                  domProps: { textContent: _vm._s(t[0]) }
                                })
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    t[2],
                                    { tag: "component", class: i },
                                    [
                                      i !== "quote"
                                        ? [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(t[1]) +
                                                "\n                  "
                                            )
                                          ]
                                        : _vm._e(),
                                      i === "quote"
                                        ? _c("p", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(t[1]) +
                                                "\n                  "
                                            )
                                          ])
                                        : _vm._e(),
                                      i === "quote"
                                        ? _c("small", [
                                            _vm._v("Kanye West, Musician")
                                          ])
                                        : _vm._e(),
                                      i === "small"
                                        ? [
                                            _c("br"),
                                            _c("small", [
                                              _vm._v(
                                                "Use 'small' tag for the headers"
                                              )
                                            ])
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }